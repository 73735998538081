'use client'
import { useSearchType } from '@/api/hooks'
import { processSearchTerm } from '@/app/util/search-term'
import { TakeoverData } from '@/app/util/searchbar-takeover'
import {
    DESKTOP_CONTAINER_HEIGHT as TOP_LEADERBOARD_DESKTOP_CONTAINER_HEIGHT,
    MOBILE_CONTAINER_HEIGHT as TOP_LEADERBOARD_MOBILE_CONTAINER_HEIGHT,
    UNIT_NAME as TOP_LEADERBOARD_UNIT_NAME,
} from '@/components/ads/top-leaderboard'
import { AdProductContext } from '@/context/ad-product'
import AdsContext from '@/context/ads'
import FavoritesContext from '@/context/favorites-manager'
import { SearchbarContext } from '@/context/searchbar-manager'
import { giphyBlack } from '@giphy/colors'
import { SearchBar as SearchBar_, SearchContext, SearchContextManager } from '@giphy/react-components'
import dynamic from 'next/dynamic'
import { useParams, useRouter } from 'next/navigation'
import { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Header } from 'ui'
import { desktopHeaderWidth } from 'ui/src/components/header'
import Logo from 'ui/src/components/logo'
import { aboveHeaderZIndex, belowHeaderZIndex, desktopWidth } from 'ui/src/constants'
import { desktop } from 'ui/src/css'
import { removeImageTypes } from 'utils/src/media'
import UserContext from '../../context/user-context'
import { Interface } from '../../styles/fonts'
import { desktopSearchbarPadding, mobileSearchbarHeight, searchbarHeight } from './constants'
import Takeover from './takeover'

const SearchDropdown = dynamic(() => import('./search-dropdown'), {})
const BodyScrollLock = dynamic(() => import('./body-scroll-lock'), {})
const OpenInAppCTA = dynamic(() => import('../../components/open-in-app'))

const isTakeoverTransparent = false
const takeoverInputSize = isTakeoverTransparent ? 590 : 540
const searchbarShrunkWidth = isTakeoverTransparent ? 400 : 360
const SearchBarComponent = styled(SearchBar_)<{ $hasTakeover?: boolean; $isStuck: boolean }>`
    input {
        font-size: 22px;
        padding-left: 14px;
        font-family: ${Interface.style.fontFamily};
        ${(props) =>
            props.$hasTakeover &&
            css`
                width: ${props.$isStuck ? searchbarShrunkWidth : takeoverInputSize}px;
                flex: unset;
            `}
    }
    ${(props) =>
        props.$hasTakeover &&
        css`
            /* separate the now shorter input and the right magnifying glass */
            justify-content: space-between;
        `}
    .giphy-search-bar-cancel {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        ${(props) =>
            props.$hasTakeover &&
            css`
                position: absolute;
                left: ${takeoverInputSize}px;
                margin: 0;
            `}
    }
`

const FixedGIPHYLogoContainer = styled.div<{ $isStuck: boolean; $hasTopLeaderboardAd: boolean }>`
    display: none;
    ${desktop(css`
        top: 0;
        display: block;
        position: absolute;
        height: ${searchbarHeight}px;
        display: flex;
        align-items: center;
        z-index: ${aboveHeaderZIndex};
    `)}
    ${({ $isStuck, $hasTopLeaderboardAd }) =>
        $isStuck &&
        desktop(css`
            position: fixed;
            top: ${$hasTopLeaderboardAd ? TOP_LEADERBOARD_DESKTOP_CONTAINER_HEIGHT - 70 : -70}px;
            transform: translate3d(0, 78px, 0);
            transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
        `)}
`

const SearchbarContainer = styled.div`
    padding: 5px 0;
    ${desktop(css`
        padding: ${desktopSearchbarPadding}px 0 0;
    `)}
`

const AnimationContainer = styled.div<{ $hasTopLeaderboardAd?: boolean }>`
    background-color: ${giphyBlack};
    width: 100%;
    z-index: ${belowHeaderZIndex};
    position: sticky;
    /* this -1px lets us know if we're offscreen to the top and "stuck" */
    top: -1px;
    ${({ $hasTopLeaderboardAd }) => $hasTopLeaderboardAd && `top: ${TOP_LEADERBOARD_MOBILE_CONTAINER_HEIGHT - 1}px;`}
    ${({ $hasTopLeaderboardAd }) =>
        $hasTopLeaderboardAd &&
        desktop(css`
            top: ${TOP_LEADERBOARD_DESKTOP_CONTAINER_HEIGHT - 1}px;
        `)}
`

const Container = styled.div<{ $isStuck?: boolean }>`
    width: 100%;
    ${desktop(css`
        transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        margin-left: auto;
    `)}
    ${({ $isStuck }) =>
        $isStuck &&
        desktop(css`
            width: calc(100% - ${desktopHeaderWidth}px);
            padding-bottom: 8px;
        `)}
`

type Props = {
    apiKey: string
    children?: ReactNode
    takeover?: TakeoverData
}

type BodyScrollLockContextProps = {
    ignoreTarget?: string
    setIgnoreTarget: (target: string) => void
}
export const BodyScrollLockContext = createContext({
    ignoreTarget: '',
    setIgnoreTarget: () => {},
} as BodyScrollLockContextProps)

const SearchInput = ({ isStuck }: { isStuck: boolean }) => {
    const { autoFocus } = useContext(SearchbarContext)
    const searchType = useSearchType()
    const { takeover } = useContext(AdProductContext)
    const placeholders = ['Search all the GIFs and Stickers', '@username to search channels']
    const [index, setIndex] = useState(0)
    const { activeChannel } = useContext(SearchContext)

    const onEnter = useCallback(
        (term: string) => {
            if (term) {
                const cleanTerm = encodeURIComponent(removeImageTypes(term).replace(/ /g, '-').replace('/', ''))
                if (activeChannel) {
                    const channel = `@${activeChannel.slug.trim().toLowerCase()}`
                    location.href = `/search/?q=${channel}+${cleanTerm}`
                } else {
                    const typeSuffix = searchType ? (searchType === 'gifs' ? '' : `-${searchType}`) : ''
                    location.href = `/search/${cleanTerm}${typeSuffix}`
                }
            }
        },
        [activeChannel, searchType]
    )
    useEffect(() => {
        // for the placeholder animation
        const timeout = setTimeout(() => setIndex(index === 0 ? 1 : 0), 3000)
        return () => clearTimeout(timeout)
    }, [index])
    return (
        <SearchbarContainer>
            <SearchBarComponent
                $isStuck={isStuck}
                $hasTakeover={!!takeover}
                placeholder={placeholders[index]}
                onEnter={onEnter}
                autoFocus={autoFocus}
            />
            {takeover && <Takeover takeover={takeover} />}
        </SearchbarContainer>
    )
}
const PageContainer = styled.div`
    ${desktop(css`
        position: relative;
        width: ${desktopWidth}px;
        margin: 0 auto;
    `)}
`

const HeaderSearchBar = ({ apiKey, children }: Props) => {
    const router = useRouter()
    const { user } = useContext(UserContext)
    const { term: encodedTerm = '' } = useParams<{ term?: string }>()
    const [cleanTerm] = processSearchTerm(encodedTerm)
    const { favorites } = useContext(FavoritesContext)
    const [ignoreTarget, setIgnoreTarget] = useState('')
    const isSticky = useRef<HTMLDivElement | null>(null)
    const [isStuck, setIsStuck] = useState(false)
    const { isAdUnitEnabled } = useContext(AdsContext)
    const hasTopLeaderboardAd = isAdUnitEnabled(TOP_LEADERBOARD_UNIT_NAME)

    useEffect(() => {
        let observer: IntersectionObserver
        if (isSticky.current) {
            observer = new IntersectionObserver(
                ([e]) => {
                    setIsStuck(e.intersectionRatio < 1)
                },
                {
                    threshold: [1],
                    // only observe the top of the search bar
                    rootMargin: `${
                        hasTopLeaderboardAd ? -TOP_LEADERBOARD_DESKTOP_CONTAINER_HEIGHT : 0
                    }px 1000px 1000px 1000px`,
                }
            )
            observer.observe(isSticky.current)
        }
        return () => observer?.disconnect()
    }, [hasTopLeaderboardAd])

    return (
        <PageContainer>
            <BodyScrollLockContext.Provider value={{ setIgnoreTarget, ignoreTarget }}>
                <SearchContextManager
                    initialTerm={cleanTerm}
                    apiKey={apiKey}
                    shouldFetchChannels={false}
                    theme={{ searchbarHeight, mobileSearchbarHeight }}
                >
                    <BodyScrollLock />
                    <Header
                        user={user}
                        onLogoClick={() => {
                            router.push('/')
                        }}
                        hasFavorites={favorites.length > 0}
                    />
                    <FixedGIPHYLogoContainer $hasTopLeaderboardAd={hasTopLeaderboardAd} $isStuck={isStuck}>
                        <Logo />
                    </FixedGIPHYLogoContainer>
                    <AnimationContainer $hasTopLeaderboardAd={hasTopLeaderboardAd} ref={isSticky}>
                        <Container $isStuck={isStuck}>
                            <SearchInput isStuck={isStuck} />
                            <SearchDropdown isStuck={isStuck} />
                        </Container>
                    </AnimationContainer>
                    {children}
                </SearchContextManager>
            </BodyScrollLockContext.Provider>
            <OpenInAppCTA />
        </PageContainer>
    )
}

export default HeaderSearchBar
